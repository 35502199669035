<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline"
            >{{ good._id ? "Edit" : "Add" }} the good details</span
          >
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <rounded-editable-avatar
            :onImageSelected="onImageSelected"
            :image="
              Object.prototype.toString.call(good.image) === '[object String]'
                ? good.image
                : null
            "
            :placeholderImage="require('@/assets/default_image.png')"
          />
          <v-container class="mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="good.name"
                  label="Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="good.serving_size"
                  label="Serving Size"
                  type="number"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="good.unit"
                  label="Unit"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="good.amount"
                  label="Number of Serving"
                  type="number"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="good.status"
                  label="Status"
                  @onChange="onChange"
                  dense
                  :items="statusOptions"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="good.limit"
                  label="Limit per day"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="4"
                v-for="(category, index) in categories"
                :key="index"
              >
                <v-text-field
                  v-model="details[`${category}`]"
                  :label="category"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="good.cost"
                  label="Cost"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="good.tags"
                  label="Tags"
                  @onChange="onChange"
                  dense
                  multiple
                  :items="tags"
                  item-text="title"
                  item-value="title"
                  chips
                  clearable
                >
                  <template #selection="{ item }">
                    <v-chip
                      :color="item.color"
                      close
                      @click:close="removeTag(item.title)"
                      class="my-1"
                      >{{ item.title }}</v-chip
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="good.description"
                  label="Description"
                  @onChange="onChange"
                  dense
                  rows="2"
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            {{ good._id ? "Save" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import RoundedEditableAvatar from "../core/RoundedEditableAvatar.vue";

export default {
  components: {
    RoundedEditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    item: Object,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      good: {
        image: null,
        details: {},
      },
      error: null,
      loading: false,
      tags: [],
      statusOptions: ["Available", "Disabled"],
      categories: [],
      details: {},
    };
  },
  methods: {
    ...mapActions({
      addGood: "good/addGood",
      updateGood: "good/editGood",
      getValues: "variable/getPartnerValues",
    }),
    onSave() {
      this.loading = true;
      var formData = new FormData();
      if (this.good.tags && this.good.tags.length) {
        this.good.tags = this.good.tags
          .filter((t) => this.tags.filter((a) => a.title == t).length > 0)
          .join();
      } else {
        this.good.tags = "";
      }
      if (this.details) {
        this.good.details = JSON.stringify(this.details);
      }

      Object.keys(this.good).map((key) => {
        if (this.good[key] != undefined) formData.append(key, this.good[key]);
      });

      if (!this.good._id) {
        this.addGood(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            if (error.respnose) {
              console.log(error.response.data.message);
              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      } else {
        this.updateGood(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            if (error.respnose) {
              console.log(error.response.data.message);

              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      }
    },
    onImageSelected(image) {
      this.good.image = image;
    },
    onClose() {
      this.good = {
        image: null,
      };
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    removeTag(tag) {
      this.good.tags = this.good.tags.filter((f) => f != tag);
    },
  },
  mounted() {
    if (this.item) {
      this.good = this.item;
      console.log(this.good.tags);
      this.details = this.good.details ? this.good.details : {};
    }

    this.getValues({ title: "Item Tags" })
      .then((values) => {
        this.tags = values.map((value) => {
          return JSON.parse(value.text);
        });
        console.log(this.tags);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.getValues({ title: "Categories" })
      .then((values) => {
        this.categories = values.map((value) => value.text);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
