<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline"
            >{{ food._id ? "Edit" : "Add" }} food item</span
          >
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <rounded-editable-avatar
            :onImageSelected="onImageSelected"
            :image="
              Object.prototype.toString.call(food.image) === '[object String]'
                ? food.image
                : null
            "
            :placeholderImage="require('@/assets/default_image.png')"
          />
          <v-container class="mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="food.name"
                  label="Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="food.serving_size"
                  label="Serving Size"
                  type="number"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="food.unit"
                  label="Unit"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.meals"
                  label="Number of Serving"
                  type="number"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="food.status"
                  label="Status"
                  @onChange="onChange"
                  dense
                  :items="statusOptions"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.limit"
                  label="Limit per day"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.fruits"
                  label="Fruits"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.vegetables"
                  label="Vegetables"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.grains"
                  label="Grains"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.protein"
                  label="Protein"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.dairy"
                  label="Dairy"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="food.cost"
                  label="Cost"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="food.prod_barcode"
                  label="Second barcode"
                  type="number"
                  @onChange="onChange"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="food.tags"
                  label="Tags"
                  @onChange="onChange"
                  dense
                  multiple
                  :items="tags"
                  item-text="title"
                  item-value="title"
                  chips
                  clearable
                >
                  <template #selection="{ item }">
                    <v-chip
                      :color="item.color"
                      close
                      @click:close="removeTag(item.title)"
                      class="my-1"
                      >{{ item.title }}</v-chip
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="food.description"
                  label="Description"
                  @onChange="onChange"
                  dense
                  rows="2"
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            {{ food._id ? "Save" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import RoundedEditableAvatar from "../core/RoundedEditableAvatar.vue";

export default {
  components: {
    RoundedEditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    item: Object,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      food: {
        image: null,
      },
      error: null,
      loading: false,
      tags: [
        { title: "High fat", color: "red" },
        { title: "High sugar", color: "red" },
        { title: "Highly processed", color: "red" },
        { title: "Low nutritional value", color: "yellow" },
        { title: "Limit quantities", color: "yellow" },
        { title: "Healthy fats", color: "green" },
        { title: "Highly nutritional", color: "green" },
      ],
      statusOptions: ["Available", "Disabled"],
    };
  },
  methods: {
    ...mapActions({
      addFood: "food/addFood",
      updateFood: "food/editFood",
      getValues: "variable/getValues",
    }),
    onSave() {
      this.loading = true;
      var formData = new FormData();
      if (this.food.tags && this.food.tags.length) {
        this.food.tags = this.food.tags
          .filter((t) => this.tags.filter((a) => a.title == t).length > 0)
          .join();
      } else {
        this.food.tags = "";
      }

      Object.keys(this.food).map((key) => {
        if (this.food[key] != undefined) formData.append(key, this.food[key]);
      });

      if (!this.food._id) {
        this.addFood(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            if (error.respnose) {
              console.log(error.response.data.message);
              this.loading = false;
              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      } else {
        this.updateFood(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            if (error.respnose) {
              console.log(error.response.data.message);
              this.loading = false;
              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      }
    },
    onImageSelected(image) {
      this.food.image = image;
    },
    onClose() {
      this.food = {
        image: null,
      };
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    removeTag(tag) {
      this.food.tags = this.food.tags.filter((f) => f != tag);
    },
  },
  mounted() {
    if (this.item) {
      this.food = this.item;
      // console.log(this.item.tags);
    }
    this.getValues({ title: "Food Tags" })
      .then((values) => {
        this.tags = values.map((value) => {
          return JSON.parse(value.text);
        });
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
