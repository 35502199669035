var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Menu',{staticStyle:{"position":"fixed","height":"50px","z-index":"1","margin-top":"-12px","background":"#DBC7FF","left":"0","right":"0"},attrs:{"barcode":true,"nutri_info":true}}),_c('div',{staticClass:"pb-5 px-15",staticStyle:{"margin-top":"40px","background":"#F4F9FF"}},[_c('v-card-title',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('label',{staticClass:"item_title"},[_vm._v("Food Management")]),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.onAddFood}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Add New Food")],1)],1),_c('v-card-subtitle',{staticClass:"mt-2"},[_c('label',{staticClass:"item_description"},[_vm._v(" Please manage your food items and details: ")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.foods,"items-per-page":15,"loading":_vm.loading,"custom-sort":_vm.customSort,"search":_vm.search,"custom-filter":_vm.customFilter,"show-expand":"","expanded":_vm.expanded,"item-key":"_id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-3",staticStyle:{"width":"140px","max-width":"140px"}},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage,"width":140,"height":100}})],1)]}},{key:"item.barcode",fn:function(ref){
var item = ref.item;
return [(item.barcode)?_c('barcode',{attrs:{"value":item.barcode,"options":{ displayValue: true },"tag":"svg"}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.name))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.name))}})]}},{key:"item.meals",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.meals))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.meals))}})]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.tags))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.tags))}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.description))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.description))}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.email))}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.type))}})]}},{key:"item.serving_size",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.serving_size)?_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.serving_size + ' ' + item.unit))}},'div',attrs,false),on)):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.serving_size + ' ' + item.unit))}})]}},{key:"item.nutrition",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"120px"},style:(item.status == 'Available' ? '' : 'color: lightgrey')},'div',attrs,false),on),[(item.fruits)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Fruits: ' + item.fruits))}}):_vm._e(),(item.vegetables)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Vegetables: ' + item.vegetables))}}):_vm._e(),(item.grains)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Grains: ' + item.grains))}}):_vm._e(),(item.protein)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Protein: ' + item.protein))}}):_vm._e(),(item.dairy)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Dairy: ' + item.dairy))}}):_vm._e()])]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{staticStyle:{"width":"120px"}},[(item.fruits)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Fruits: ' + item.fruits))}}):_vm._e(),(item.vegetables)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Vegetables: ' + item.vegetables))}}):_vm._e(),(item.grains)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Grains: ' + item.grains))}}):_vm._e(),(item.protein)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Protein: ' + item.protein))}}):_vm._e(),(item.dairy)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered('Dairy: ' + item.dairy))}}):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditFood(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteFood(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.type == 'Food')?_c('div',[_vm._v(" "+_vm._s(item.balance.length == 0 ? 0 : item.balance[0].amount)+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var subitem = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.subHeaders,"items":_vm.distributionData[subitem._id],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.freezer)?_c('div',{staticClass:"ma-3",staticStyle:{"width":"70px","max-width":"70px"}},[_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.freezer.image
                        ? item.freezer.image
                        : _vm.placeholderImage}})],1)],1):_vm._e()]}},{key:"item.organization",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"},domProps:{"innerHTML":_vm._s(item.freezer.organization)}})]}},{key:"item.manager",fn:function(ref){
                        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
                  item.freezer.first_name + ' ' + item.freezer.last_name
                )}}),_c('div',{domProps:{"innerHTML":_vm._s(item.freezer.email)}}),_c('div',{domProps:{"innerHTML":_vm._s(item.freezer.phone)}})]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.freezer.type)}})]}},{key:"item.address",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.freezer.address)}})]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [(!item.isEditing)?_c('div',{domProps:{"innerHTML":_vm._s(item.amount)}}):_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"20px"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEditBalanceDone(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onCancelEditBalance(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditBalance(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteBalance(item, subitem)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}}],null,true)})],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this food? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteBalanceDialog),callback:function ($$v) {_vm.deleteBalanceDialog=$$v},expression:"deleteBalanceDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this balance?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteBalanceDialog = !_vm.deleteBalanceDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteBalanceAction}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),(_vm.foodDialog)?_c('food-dialog',{attrs:{"dialog":_vm.foodDialog,"item":_vm.selected,"onCloseDialog":_vm.onCloseDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }