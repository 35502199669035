<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
    />
    <div class="pb-5 px-15" style="margin-top: 40px; background: #F4F9FF;">
      <v-card-title class="d-flex" style="justify-content: space-between;">
        <label class="item_title">Item Management</label>
        <v-btn color="primary" rounded @click="onAddGood"
          ><v-icon>mdi-plus</v-icon>Add New Item</v-btn
        >
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          Please manage your items and details:
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="goods"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
        :custom-sort="customSort"
        :search="search"
        :custom-filter="customFilter"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div class="ma-3" style="width: 140px; max-width: 140px;">
            <v-img
              :src="item.image ? item.image : placeholderImage"
              :width="140"
              :height="100"
            />
          </div>
        </template>
        <template v-slot:[`item.barcode`]="{ item }">
          <barcode
            :value="item.barcode"
            :options="{ displayValue: true }"
            tag="svg"
            v-if="item.barcode"
          />
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.name)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.name)" v-else />
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.amount)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.amount)" v-else />
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.tags)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.tags)" v-else />
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.description)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.description)" v-else />
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div v-html="getFiltered(item.email)" />
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div v-html="getFiltered(item.type)" />
        </template>
        <template v-slot:[`item.serving_size`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.serving_size + ' ' + item.unit)"
                v-if="item.serving_size"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div
            v-html="getFiltered(item.serving_size + ' ' + item.unit)"
            v-else
          />
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <div v-if="item.details" style="width: 120px">
            <div
              v-for="detail in Object.entries(
                item.details
              ).map(([key, value]) => ({ key, value }))"
              :key="detail.key"
            >
              {{ detail.key }} : {{ detail.value }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-checkbox v-model="item.checked" @change="onChange(item)">
                  </v-checkbox>
                </div>
              </template>
              {{ item.status }}
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onEditGood(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onDeleteGood(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="goToHistory(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-history</v-icon></v-btn
                >
              </template>
              Distribution History
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <div v-if="item.type == 'Good'">
            {{ item.balance.length == 0 ? 0 : item.balance[0].amount }}
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this good? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <good-dialog
      :dialog="goodDialog"
      :item="selected"
      :onCloseDialog="onCloseDialog"
      v-if="goodDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import GoodDialog from "../../components/partner/GoodDialog.vue";
export default {
  components: { Menu, GoodDialog },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Number of Serving", value: "amount" },
        { text: "Serving size", value: "serving_size" },
        { text: "Details", value: "details" },
        { text: "Tags", value: "tags" },
        { text: "Other Info", value: "description" },
        { text: "Actions", value: "actions" },
        { text: "Barcode", value: "barcode" },
      ],
      selected: null,
      placeholderImage: require("@/assets/default_image.png"),
      profile: { email: "" },
      selectedFile: null,
      deleteDialog: false,
      goodDialog: false,
      search: "",
      goods: [],
    };
  },
  computed: {
    ...mapGetters({
      allGoods: "good/getAllGoods",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions("good", {
      fetchAllGoods: "fetchAllGoods",
      addGood: "addGood",
      editGood: "editGood",
      deleteGood: "deleteGood",
      setBalance: "setBalance",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange(item) {
      console.log(item);
      if (item.checked) {
        item.status = "Available";
      } else {
        item.status = "Disabled";
      }
      this.selected = item;
      this.goods = [...this.goods];
      this.editGood({ _id: item._id, status: item.status });
    },
    onSelectGood(item) {
      console.log(item);
    },
    onAddGood() {
      this.selected = null;
      this.goodDialog = true;
    },
    onEditGood(item) {
      this.selected = { ...item };
      this.goodDialog = true;
    },
    onDeleteGood(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onIntake() {
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteGood(this.selected)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    onCloseDialog() {
      this.goodDialog = false;
      this.selected = null;
    },
    goToHistory(item) {
      console.log(item);
    },
  },
  created() {
    this.loading = true;
    this.fetchAllGoods()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
  watch: {
    keyword(newValue) {
      this.search = newValue;
    },
    allGoods(newValue) {
      if (this.$route.query.new) {
        this.goods = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.goods = newValue;
      }
      this.goods = this.goods.map((a) => {
        return { ...a, checked: a.status == "Available" ? true : false };
      });
      console.log(this.goods);
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.good_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
