<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
      :barcode="true"
      :nutri_info="true"
    />
    <div class="pb-5 px-15" style="margin-top: 40px; background: #F4F9FF;">
      <v-card-title class="d-flex" style="justify-content: space-between;">
        <label class="item_title">Food Management</label>
        <v-btn color="primary" rounded @click="onAddFood"
          ><v-icon>mdi-plus</v-icon>Add New Food</v-btn
        >
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          Please manage your food items and details:
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="foods"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
        :custom-sort="customSort"
        :search="search"
        :custom-filter="customFilter"
        show-expand
        :expanded.sync="expanded"
        item-key="_id"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div class="ma-3" style="width: 140px; max-width: 140px;">
            <v-img
              :src="item.image ? item.image : placeholderImage"
              :width="140"
              :height="100"
            />
          </div>
        </template>
        <template v-slot:[`item.barcode`]="{ item }">
          <barcode
            :value="item.barcode"
            :options="{ displayValue: true }"
            tag="svg"
            v-if="item.barcode"
          />
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.name)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.name)" v-else />
        </template>
        <template v-slot:[`item.meals`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.meals)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.meals)" v-else />
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.tags)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.tags)" v-else />
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.description)"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div v-html="getFiltered(item.description)" v-else />
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div v-html="getFiltered(item.email)" />
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div v-html="getFiltered(item.type)" />
        </template>
        <template v-slot:[`item.serving_size`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-html="getFiltered(item.serving_size + ' ' + item.unit)"
                v-if="item.serving_size"
                v-bind="attrs"
                v-on="on"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
              />
            </template>
            {{ item.status }}
          </v-tooltip>
          <div
            v-html="getFiltered(item.serving_size + ' ' + item.unit)"
            v-else
          />
        </template>
        <template v-slot:[`item.nutrition`]="{ item }">
          <v-tooltip bottom v-if="item.status != 'Available'">
            <template v-slot:activator="{ on, attrs }">
              <div
                style="width: 120px"
                :style="item.status == 'Available' ? '' : 'color: lightgrey'"
                v-bind="attrs"
                v-on="on"
              >
                <div
                  v-if="item.fruits"
                  v-html="getFiltered('Fruits: ' + item.fruits)"
                />

                <div
                  v-if="item.vegetables"
                  v-html="getFiltered('Vegetables: ' + item.vegetables)"
                />

                <div
                  v-if="item.grains"
                  v-html="getFiltered('Grains: ' + item.grains)"
                />
                <div
                  v-if="item.protein"
                  v-html="getFiltered('Protein: ' + item.protein)"
                />
                <div
                  v-if="item.dairy"
                  v-html="getFiltered('Dairy: ' + item.dairy)"
                />
              </div>
            </template>
            {{ item.status }}
          </v-tooltip>
          <div style="width: 120px" v-else>
            <div
              v-if="item.fruits"
              v-html="getFiltered('Fruits: ' + item.fruits)"
            />

            <div
              v-if="item.vegetables"
              v-html="getFiltered('Vegetables: ' + item.vegetables)"
            />

            <div
              v-if="item.grains"
              v-html="getFiltered('Grains: ' + item.grains)"
            />
            <div
              v-if="item.protein"
              v-html="getFiltered('Protein: ' + item.protein)"
            />
            <div
              v-if="item.dairy"
              v-html="getFiltered('Dairy: ' + item.dairy)"
            />
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-checkbox v-model="item.checked" @change="onChange(item)">
                  </v-checkbox>
                </div>
              </template>
              {{ item.status }}
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onEditFood(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onDeleteFood(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <div v-if="item.type == 'Food'">
            {{ item.balance.length == 0 ? 0 : item.balance[0].amount }}
          </div>
        </template>
        <template v-slot:expanded-item="{ item: subitem }">
          <td :colspan="headers.length" class="px-0">
            <v-data-table
              :headers="subHeaders"
              :items="distributionData[subitem._id]"
              hide-default-footer
              class="px-3"
            >
              <template v-slot:[`item.image`]="{ item }">
                <div
                  class="ma-3"
                  style="width: 70px; max-width: 70px;"
                  v-if="item.freezer"
                >
                  <v-avatar class="elevation-6 my-3 ml-5">
                    <v-img
                      :src="
                        item.freezer.image
                          ? item.freezer.image
                          : placeholderImage
                      "
                    />
                  </v-avatar>
                </div>
              </template>
              <template v-slot:[`item.organization`]="{ item }">
                <div
                  v-html="item.freezer.organization"
                  style="max-width: 100px"
                />
              </template>
              <template v-slot:[`item.manager`]="{ item }">
                <div
                  v-html="
                    item.freezer.first_name + ' ' + item.freezer.last_name
                  "
                />
                <div v-html="item.freezer.email" />
                <div v-html="item.freezer.phone" />
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div v-html="item.freezer.type" />
              </template>
              <template v-slot:[`item.address`]="{ item }">
                <div v-html="item.freezer.address" />
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div v-html="item.amount" v-if="!item.isEditing" />
                <div v-else class="d-flex align-center">
                  <v-text-field
                    v-model="item.amount"
                    class="mt-0 pt-0"
                    style="width: 20px"
                  />
                  <v-btn icon @click="onEditBalanceDone(item)"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                  <v-btn icon @click="onCancelEditBalance(item)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onEditBalance(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    Edit
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onDeleteBalance(item, subitem)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                    Delete
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this food? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteBalanceDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this balance?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteBalanceDialog = !deleteBalanceDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteBalanceAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <food-dialog
      :dialog="foodDialog"
      :item="selected"
      :onCloseDialog="onCloseDialog"
      v-if="foodDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import FoodDialog from "../../components/admin/FoodDialog.vue";
export default {
  components: { Menu, FoodDialog },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Number of Serving", value: "meals" },
        { text: "Serving size", value: "serving_size" },
        { text: "Nutrition", value: "nutrition" },
        { text: "Tags", value: "tags" },
        { text: "Other Info", value: "description" },
        { text: "Actions", value: "actions" },
        { text: "Barcode", value: "barcode" },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "", value: "image", sortable: false },
        { text: "Organization", value: "organization" },
        { text: "Manager", value: "manager" },
        { text: "Type", value: "type" },
        { text: "Address", value: "address" },
        { text: "Amount", value: "amount" },
        { text: "Actions", value: "actions" },
      ],
      selected: null,
      placeholderImage: require("@/assets/default_image.png"),
      profile: { email: "" },
      selectedFood: null,
      deleteDialog: false,
      deleteBalanceDialog: false,
      foodDialog: false,
      search: "",
      foods: [],
      expanded: [],
      distributionData: {},
    };
  },
  computed: {
    ...mapGetters({
      allFoods: "food/getAllFoods",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllFoods: "food/fetchAllFoods",
      addFood: "food/addFood",
      editFood: "food/editFood",
      deleteFood: "food/deleteFood",
      setBalance: "food/setBalance",
      getBalanceDistribution: "food/getBalanceDistribution",
      editBalance: "freezer/editBalance",
      deleteBalance: "freezer/deleteBalance",
    }),
    onChange(item) {
      console.log(item);
      if (item.checked) {
        item.status = "Available";
      } else {
        item.status = "Disabled";
      }
      this.selected = item;
      this.foods = [...this.foods];
      this.editFood({ _id: item._id, status: item.status });
    },
    onSelectFood(item) {
      console.log(item);
    },
    onAddFood() {
      this.selected = null;
      this.foodDialog = true;
    },
    onEditFood(item) {
      this.selected = item;
      this.foodDialog = true;
    },
    onDeleteFood(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onEditBalance(item) {
      item.isEditing = true;
      this.distributionData = { ...this.distributionData };
    },
    onEditBalanceDone(item) {
      this.editBalance({ _id: item._id, amount: item.amount })
        .then(() => {
          item.isEditing = false;
          this.distributionData = { ...this.distributionData };
        })
        .catch((error) => console.log(error));
    },
    onDeleteBalance(item, food) {
      this.selected = item;
      this.selectedFood = food;
      this.deleteBalanceDialog = true;
    },
    onCancelEditBalance(item, food) {
      console.log(item, food);
      item.isEditing = false;
      this.distributionData = { ...this.distributionData };
    },
    onIntake() {
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteFood(this.selected)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.response.data.message);
          }
        });
    },
    deleteBalanceAction() {
      this.deleteBalanceDialog = false;
      this.loading = true;
      this.deleteBalance({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
          this.distributionData[this.selectedFood._id] = this.distributionData[
            this.selectedFood._id
          ].filter((data) => data._id != this.selected._id);
          this.distributionData = { ...this.distributionData };
        })
        .catch((error) => console.log(error));
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        console.log(`${item.serving_size} ${item.unit}`, this.keyword);
        if (
          (item.name &&
            item.name.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (`${item.meals}` &&
            `${item.meals}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.tags.length &&
            item.tags
              .join(",")
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (`${item.serving_size} ${item.unit}` &&
            `${item.serving_size} ${item.unit}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.fruits &&
            `Fruits: ${item.fruits}` &&
            `Fruits: ${item.fruits}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.vegetables &&
            `Vegetables: ${item.vegetables}` &&
            `Vegetables: ${item.vegetables}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.grains &&
            `Grains: ${item.grains}` &&
            `Grains: ${item.grains}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.protein &&
            `Protein: ${item.protein}` &&
            `Protein: ${item.protein}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.dairy &&
            `Dairy: ${item.dairy}` &&
            `Dairy: ${item.dairy}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.description &&
            item.description
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (`${item.barcode}` &&
            `${item.barcode}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      // console.log(item);
      return searchFilter;
    },
    onCloseDialog() {
      this.foodDialog = false;
      this.selected = null;
    },
    getDistributionData(_id) {
      this.getBalanceDistribution({ _id }).then((res) => {
        this.distributionData[_id] = res;
        this.distributionData = { ...this.distributionData };
      });
    },
  },
  created() {
    this.loading = true;
    this.fetchAllFoods()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
  watch: {
    keyword(newValue) {
      this.search = newValue;
    },
    allFoods(newValue) {
      if (this.$route.query.new) {
        this.foods = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.foods = newValue;
      }
      this.foods = this.foods.map((a) => {
        return { ...a, checked: a.status == "Available" ? true : false };
      });
    },
    expanded(newValue) {
      for (var i = 0; i < newValue.length; i++) {
        if (!this.distributionData[newValue[i]._id]) {
          this.getDistributionData(newValue[i]._id);
        }
      }
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.food_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
