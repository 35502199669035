var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Menu',{staticStyle:{"position":"fixed","height":"50px","z-index":"1","margin-top":"-12px","background":"#DBC7FF","left":"0","right":"0"}}),_c('div',{staticClass:"pb-5 px-15",staticStyle:{"margin-top":"40px","background":"#F4F9FF"}},[_c('v-card-title',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('label',{staticClass:"item_title"},[_vm._v("Item Management")]),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.onAddGood}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Add New Item")],1)],1),_c('v-card-subtitle',{staticClass:"mt-2"},[_c('label',{staticClass:"item_description"},[_vm._v(" Please manage your items and details: ")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.goods,"items-per-page":15,"loading":_vm.loading,"custom-sort":_vm.customSort,"search":_vm.search,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-3",staticStyle:{"width":"140px","max-width":"140px"}},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage,"width":140,"height":100}})],1)]}},{key:"item.barcode",fn:function(ref){
var item = ref.item;
return [(item.barcode)?_c('barcode',{attrs:{"value":item.barcode,"options":{ displayValue: true },"tag":"svg"}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.name))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.name))}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.amount))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.amount))}})]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.tags))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.tags))}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.description))}},'div',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.description))}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.email))}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.type))}})]}},{key:"item.serving_size",fn:function(ref){
var item = ref.item;
return [(item.status != 'Available')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.serving_size)?_c('div',_vm._g(_vm._b({style:(item.status == 'Available' ? '' : 'color: lightgrey'),domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.serving_size + ' ' + item.unit))}},'div',attrs,false),on)):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.serving_size + ' ' + item.unit))}})]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.details)?_c('div',{staticStyle:{"width":"120px"}},_vm._l((Object.entries(
              item.details
            ).map(function (ref) {
                        var key = ref[0];
                        var value = ref[1];

                        return ({ key: key, value: value });
})),function(detail){return _c('div',{key:detail.key},[_vm._v(" "+_vm._s(detail.key)+" : "+_vm._s(detail.value)+" ")])}),0):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditGood(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteGood(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToHistory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_vm._v(" Distribution History ")])],1)]}},{key:"item.balance",fn:function(ref){
            var item = ref.item;
return [(item.type == 'Good')?_c('div',[_vm._v(" "+_vm._s(item.balance.length == 0 ? 0 : item.balance[0].amount)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this good? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),(_vm.goodDialog)?_c('good-dialog',{attrs:{"dialog":_vm.goodDialog,"item":_vm.selected,"onCloseDialog":_vm.onCloseDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }